@mixin bg($size: contain, $position: center, $repeat: no-repeat) {
    background-repeat: $repeat;
    background-position: $position;
    background-size: $size;
}

@mixin flex($justify: center, $align: center) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin inline-flex($justify: center, $align: center) {
    display: inline-flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin mediaSmallDesktop() {
    @media (max-width: 1660px) {
        @content;
    }
}

@mixin mediaLaptop() {
    @media (max-width: 1400px) {
        @content;
    }
}

@mixin mediaTablet() {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin mediaPhone() {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mediaSmallPhone() {
    @media (max-width: 420px) {
        @content;
    }
}