@import 'settings/mixins';
@import 'settings/base';


html {
  min-height: 100%;
  position: relative;
  *, *:before, *:after {
      box-sizing: border-box;
  }
}


body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.7;
  color: #565656;
  background: #fff;
}

ol{
    list-style: none;
    counter-reset: myCounter;
}

ol li:before{
    counter-increment: myCounter;
    content:counter(myCounter)".";
    margin-right: 8px;
    display: inline-block;
    color: #3883e8;
    text-align: center;
}

.clearfix:after{
    content: "";
    display: table;
    clear: both;
}

textarea{
    resize: none;
}

.contacts_title{
    line-height: 1.25;
}

.service_title{
    line-height: 1.3;
    text-decoration: none;
}

.service_price_item .service_title{
    display: block;
}

.service_title:hover{
    color: #3883e8;
}

button, .button{
    margin-bottom: 0;
}

ul ul, ul ol, ol ol, ol ul{
    margin: 0;
}

.button, button, input[type="submit"], input[type="reset"], input[type="button"]{
    height: auto;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.button_link{
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 2;
    line-height: normal;
}

button, .button_link{
    position: relative;
}

button:hover, .button_link:hover{
    color: #fff;
}

.button_link:hover{
    background-color: #524ba8;
}

h1, h2, h3, h4, h5, h6{
    letter-spacing: normal;
}

.container{
    max-width: 1660px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

button{
    outline: none;
    border: none;
    cursor: pointer;
}

.header_top {
    background-color: rgba(0,0,0,0.8);
    .logo_mobile {
        display: none;
        @include bg(contain, left center);
        background-image: url(../img/logo.png);
        width: 19.5%;
        height: 33px;
        @media (max-width: 1024px) {
            display: block;
        }
        @include mediaPhone {
            width: 150px;
            height: 24px;
        }
    }
    .burger_btn {
        display: none;
        width: 36px;
        height: 26px;
        position: relative;
        &_add {
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: #fff;
            &:before, &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fff;
                border-radius: 2px;
            }
            &:before {
                top: 0;
            }
            &:after {
                bottom: 0;
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 10px 0;
        .burger_btn {
            display: flex;
        }
        .mobile_flex {
            display: flex;
        }
    }
    @include mediaPhone {
        padding: 6px 0;
    }
}

.header .mmenu_nav {
    width: 60.4%;
    &_ul {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        height: 58px;
        list-style: none;
        .level1 {
            margin-bottom: 0;
        }
    }
}

header nav li.level1 {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: 100%;
    &.active:after {
        content: '';
        position: absolute;
        height: 6px;
        width: 100%;
        background-color: $brown;
        left: 0;
        bottom: 0;
    }
}

header nav li a{
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.5s;
}

header nav li.active>a, header nav li a:hover{
    color: $brown;
}

nav li.level1 ul{
    position: absolute;
    top: 100%;
    left: -9999px;
    opacity: 0;
    z-index: 100;
    background-color: #3883e8;
    padding: 20px 22px;
    width: 270px;
    list-style: none;
    transition: opacity 0.4s;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.52);
    background-color: #313131;
    display: block;
    height: auto;
}

nav li.level1:hover ul{
    left: 0;
    opacity: 1;
}

nav li.level2{
    line-height: normal;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    display: block;
}

nav li.level2.last{
    margin-bottom: 0;
}

nav .container{
    overflow: unset;
}

.logo {
    @include bg(contain, left center);
    background-image: url(../img/logo.png);
}

.header_bottom {
    color: #5a5458;
    font-weight: 400;
    font-size: 16px;
    box-shadow: 0 7px 29px rgba(79, 79, 79, 0.06);
    background-color: rgba(#fff, 0.85);
    @include mediaTablet {
        font-size: 14px;
    }
    @include mediaPhone {
        display: none;
    }
    .logo {
        width: 19.5%;
        height: 33px;
    }
    &_block {
        position: relative;
        padding-left: 26px;
        @include mediaTablet {
            padding-left: 20px;
        }
        &_adrress {
            margin-bottom: 6px;
        }
        &_phone {
            font-size: 33px;
            font-weight: 400;
            text-decoration: none;
            color: #5a5458;
            transition: color 0.4s;
            &:hover {
                color: $brown;
            }
            @include mediaTablet {
                font-size: 16px;
            }
        }
        .svg_icon {
            position: absolute;
            stroke: #ddbc6f;
            fill: transparent;
            width: 14px;
            height: 22px;
            left: 0;
            top: 4px;
        }
    }
    .social_links {
        padding: 35px 0;
        min-width: 70px;
    }
}

.social_links {
    letter-spacing: -0.3em;
    &_item {
        width: 46px;
        height: 46px;
        margin-right: 20px;
        border: 2px solid #4a403f;
        border-radius: 50%;
        vertical-align: top;
        letter-spacing: normal;
        transition: all 0.4s;
        @include mediaTablet {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            border-color: $brown;
            .svg_icon {
                fill: $brown;
            }
        }
        &.instagram .svg_icon {
            width: 18px;
            height: 18px;
            @include mediaTablet {
                width: 14px;
                height: 14px;
            }
        }
        &.vk .svg_icon {
            width: 20px;
            height: 14px;
            @include mediaTablet {
                width: 14px;
            }
        }
        .svg_icon {
            fill: #4a403f;
            transition: fill 0.4s;
        }
    }
}

.header {
    z-index: 2;
    @include mediaLaptop {
        .logo {
            display: none;
        }
    }
}

.home {
    .header {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        @include mediaLaptop {
            position: relative;
        }
    }
}

.header .button_feedback{
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $brown;
    outline: none;
    border: none;
    padding: 0 18px;
    box-shadow: none;
    text-align: center;
    min-width: auto;
    height: 58px;
    &:hover {
        background-color: darken($brown, 20%);
    }
    @media (max-width: 1024px) {
        height: 40px;
        margin-left: auto;
        margin-right: 20px;
        font-size: 18px;
        font-weight: 500;
    }
    @include mediaPhone {
        background-color: transparent;
        padding: 0;
        &:hover {
            background-color: transparent;
        }
        span {
            display: none;
        }
    }
    .svg_icon {
        margin-right: 12px;
        fill: #fff;
        width: 22px;
        height: 22px;
        @include mediaPhone {
            width: 18px;
            height: 18px;
        }
        @include mediaPhone {
            width: 26px;
            height: 26px;
            margin-right: 0;
        }
    }
}

.main_slider {
    position: relative;
    margin-bottom: 110px;
    @include mediaLaptop {
        margin-bottom: 50px;
    }
    @include mediaPhone {
        margin-bottom: 30px;
    }
    .owl-nav {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: calc((100% - 175px) / 2);
        @include mediaLaptop {
            bottom: 50%;
        }
        .owl-prev, .owl-next {
            position: absolute;
            top: -12px;
            height: 24px;
            width: 32px;
            &:before {
                content: '';
                width: 100%;
                height: 2px;
                background-color: #fff;
                position: absolute;
                left: 0;
                top: 11px;
            }
            &:after {
                content: '';
                position: absolute;
                border-left: 2px solid #fff;
                border-top: 2px solid #fff;
                width: 16px;
                height: 16px;
                top: 4px;
            }
        }
        .owl-prev {
            left: 52px;
            @media (max-width: 1840px) {
                left: 15px;
            }
            &:after {
                left: 0px;
                transform: rotate(-45deg);
            }
        }
        .owl-next {
            right: 52px;
            @media (max-width: 1840px) {
                right: 15px;
            }
            &:after {
                right: 0px;
                transform: rotate(135deg);
            }
        }
    }
    .slide_item {
        //height: 760px;
        min-height: 760px;
        height: 66.6vh;
        padding-top: 175px;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center;
        position: relative;
        color: #656565;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(45deg, rgba(218, 218, 218, .99), rgba(255, 255, 255, .0));
        }
        @media (max-width: 1840px) {
            .container {
                padding-left: 60px;
                padding-right: 60px;
            }
        }
        @include mediaLaptop {
            padding-top: 0;
            min-height: auto;
            height: calc(66.6vh - 174px);
        }
        @include mediaTablet {
            min-height: auto;
            height: 340px;
        }
        @include mediaPhone {
            height: 240px !important;
            .container {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        &_title {
            font-size: 78px;
            line-height: 1.1;
            font-family: $tinos;
            margin-bottom: 40px;
            @include mediaLaptop {
                font-size: 36px;
                margin-bottom: 16px;
            }
            @include mediaPhone {
                font-size: 22px;
                margin-bottom: 12px;
            }
        }
        &_text {
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 50px;
            width: 50%;
            @include mediaLaptop {
                font-size: 26px;
                margin-bottom: 20px;
                width: 60%;
            }
            @include mediaTablet {
                width: 100%;
            }
            @include mediaPhone {
                font-size: 14px;
                margin-bottom: 12px;
            }
            p {
                margin: 0;
            }
        }
    }
}

.vantages {
    margin-bottom: 140px;
    @include mediaLaptop {
        margin-bottom: 50px;
    }
    @include mediaTablet {
        >.flex {
            flex-wrap: wrap;
        }
    }
    @include mediaPhone {
        margin-bottom: 30px;
    }
    &_item {
        width: 21.5%;
        @include mediaTablet {
            width: 44%;
            margin-bottom: 14px;
        }
        &_img {
            width: 100px;
            min-width: 100px;
            margin-right: 12px;
            @include mediaLaptop {
                margin-right: 10px;
                width: 70px;
                min-width: 70px;
            }
            @include mediaPhone {
                width: 50px;
                min-width: 50px;
            }
            img {
                max-width: 106px;
                max-height: 85px;
                @include mediaLaptop {
                    max-width: 70px;
                    max-height: 60px;
                }
                @include mediaPhone {
                    max-width: 50px;
                    max-height: 40px;
                }
            }
        }
        &_title {
            font-size: 18px;
            @include mediaLaptop {
                font-size: 16px;
            }
            @include mediaPhone {
                font-size: 12px;
            }
        }
    }
}

.button_link{
    min-width: 300px;
    padding: 20px;
    box-shadow: 0 9px 18px rgba(79, 79, 79, 0.18);
    background-color: #7166f1;
    text-align: left;
    color: #ffffff !important;
    font-size: 18px;
    position: relative;
    font-weight: 400;
    display: inline-block;
    box-sizing: border-box;
    text-decoration: none;
}

.button_link:before{
    content: '';
    width: 48px;
    height: 1px;
    background-color: #fff;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 50%;
}

.button_link:after{
    content: '';
    top: 50%;
    margin-top: -3px;
    right: 20px;
    height: 6px;
    width: 6px;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    position: absolute;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.popup .button_green:after{
    right: 60px;
}

.main_info .button_link{
    margin-top: 30px;
}

.main_banner{
    @include bg(cover);
    height: 460px;
    position: relative;
    margin-bottom: 170px;
    @include mediaLaptop {
        margin-bottom: 60px;
        height: 380px;
    }
    @include mediaPhone {
        height: 240px;
        margin-bottom: 40px;
    }
    &_text {
        width: 778px;
        max-width: 90%;
        padding: 34px 56px 48px;
        background-color: rgba(#fff, 0.8);
        @include mediaLaptop {
            padding: 20px 30px;
        }
        @include mediaPhone {
            width: 100%;
            max-width: none;
        }
    }
    &_title {
        line-height: 1.1;
        font-size: 50px;
        margin-bottom: 16px;
        @include mediaLaptop {
            font-size: 36px;
        }
        @include mediaPhone {
            font-size: 22px;
        }
    }
    &_desc {
        font-size: 36px;
        line-height: 1.3;
        font-weight: 300;
        margin-bottom: 36px;
        @include mediaLaptop {
            font-size: 28px;
        }
        @include mediaPhone {
            font-size: 16px;
        }
    }
}

.before_title{
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    color: #3883e8;
}

.main_news {
    background-color: rgba(#9c8980, 0.15);
    padding: 80px 0;
    @include mediaLaptop {
        padding: 40px 0;
    }
    @include mediaPhone {
        padding: 20px 0;
    }
}

.main_news .main_news_img{
    width: 45%;
    height: 600px;
    @include bg(cover);
    background-image: url(../img/newsbg.jpg);
    @include mediaLaptop {
        display: none;
    }
}

.main_news .main_news_text{
    width: 50%;
    font-weight: 300;
    @include mediaLaptop {
        width: 100%;
    }
}

.news_text{
    margin: 10px 0;
}

.news_link{
    color: #4a403f;
    font-size: 30px;
}

.news_link:hover{
    color: #4a403f;
}

.news_date{
    color: #afafaf;
}

.main_news_text .main_news_item:last-child{
    margin-bottom: 0;
}

.main_service_price{
    padding-top: 60px;
    padding-bottom: 20px;
    background-color: #f9fbfe;
    position: relative;
}

.main_service_price_container{
    clear: both;
    position: relative;
    z-index: 2;
    letter-spacing: -0.4em;
}

.service_price_item{
    padding: 20px;
    background-color: #fff;
    width: 31%;
    display: inline-block;
    margin-right: 3.5%;
    box-sizing: border-box;
    text-align: center;
    font-size: 30px;
    margin-bottom: 45px;
}

.service_price_item:nth-child(3n){
    /* margin-right: 0; */
}

.service_price_item img{
    width: 100%;
    display: block;
}

.service_price_item .service_title{
    margin-top: 25px;
    display: block;
    text-transform: none;
}

.page .service_price_item .service_title{
    text-transform: none;
}

.service_price_item p{
    margin: 20px 0;
}

.service_price_item .price span{
    font-weight: 600;
}

.service_price_button:before{
    content: none;
}

.service_price_button{
    padding: 22px 0;
    width: 380px;
    max-width: 90%;
    color: #ffffff !important;
    font-size: 30px;
    font-weight: 300;
    box-shadow: 0 9px 18px rgba(75, 75, 75, 0.18);
    background-color: #74d5ab;
    margin-top: 20px;
    margin-bottom: 30px;
}

.main_service_price:before{
    bottom: 0;
    left: 0;
    width: 65%;
    height: 50%;
}

.main_service_price:after{
    right: 0;
    top: 0;
    width: 40%;
    height: 33%;
}

.owl-dots{
    text-align: center;
    margin-top: 15px;
}

.owl-dots button.owl-dot{
    width: 18px;
    height: 18px;
    background: #cfcfcf;
    border-radius: 50%;
    margin-right: 13px;
}

.owl-dots button.owl-dot:last-child{
    margin: 0;
}

.owl-dots button.owl-dot.active{
    background: $brown;
}

.footer_contacts_title{
    display: block;
    font-size: 18px;
    font-weight: 300;
}

.footer_contacts_block, .footer_contacts_block a{
    color: #ffffff;
    text-decoration: none;
    font-size: 24px;
    font-weight: 500;
}

.footer_contacts_block{
    margin-bottom: 25px;
    padding-left: 50px;
    position: relative;
}

.footer_contacts_block:before{
    content: '';
    position: absolute;
    width: 32px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
    -webkit-background-size: contain;
    background-size: contain;
}

.contacts_phone{
    margin-top: 35px;
}

.contacts_social, .footer_social_links{
    opacity: 0.6;
}

.contacts_social .instagram, .footer_social_links .instagram{
    background-image: url(../img/svg/instagram-white.svg);
    -webkit-background-size: 90%;
    background-size: 90%;
}

.contacts_social .facebook, .footer_social_links .facebook{
    background-image: url(../img/svg/fb-white.svg);
}

.footer_social_links .facebook{
    -webkit-background-size: auto 70%;
    background-size: auto 70%;
}

.contacts_social .vk, .footer_social_links .vk{
    background-image: url(../img/svg/vk-white.svg);
    margin-right: 0;
}

.contacts_phone:before{
    background-image: url(../img/svg/phone_footer.svg);
}

.contacts_mail:before{
    background-image: url(../img/svg/mail_footer.svg);
}

.contacts_address:before{
    background-image: url(../img/svg/map_footer.svg);
    -webkit-background-size: 80%;
    background-size: 80%;
}

.contacts_time:before{
    background-image: url(../img/svg/clock_footer.svg);
}

.right_contacts_form .input_block input{
    height: 40px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(255,255,255,0.35);
    color: #fff;
    font-weight: 300;
}

.right_contacts_form .input_block input::placeholder{
    color: #000;
}

.popup_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
    display: none;
    opacity: 0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.popup_bg.active{
    opacity: 1;
}

.popup_bg .popup_close{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.popup_bg .popup, .thanks_block{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 544px;
    max-width: 90%;
    padding: 34px 40px 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.popup_close_x{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.popup_close_x:before, .popup_close_x:after{
    content: '';
    height: 17px;
    width: 2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    background-color: #000;
    left: 7px;
    top: -1px;
}

.popup_close_x:before{
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.popup_title, .thanks_title{
    text-transform: uppercase;
    font-size: 26px;
    color: #181818;
    text-align: center;
    margin-top: 0;
    @include mediaPhone {
        font-size: 22px;
    }
}

.popup form{
    margin: 0;
    text-align: center;
}

.popup input, .popup textarea{
    width: 100%;
    height: 46px;
    border: 1px solid #d0d0d0 !important;
    font-size: 16px;
    position: relative;
}

.popup textarea{
    height: 170px;
    @include mediaPhone {
        height: 80px;
    }
}

.popup .placeholder{
    position: relative;
}

.popup .placeholder .required_add{
    color: #f84141;
    font-size: 16px;
    position: absolute;
    top: 13px;
    z-index: 6;
    right: 10px;
    display: none;
}

.popup .placeholder input:required + .required_add{
    display: block;
}

.popup .button_green{
    display: block;
    margin: 0 auto;
    padding-left: 60px;
}

.popup .button_green:before{
    right: 60px;
}

button.owl-dot:after{
    content: none;
}

.mobile_info_img{
    display: none;
}

.breadcrumb{
    list-style: none;
    &-item {
        display: inline-block;
        font-size: 20px;
        @include mediaTablet {
            font-size: 16px;
        }
        @include mediaPhone {
            font-size: 14px;
        }
        &.active {
            color: $brown;
        }
        &:before {
            content: none;
        }
    }
}

.breadcrumb li a{
    color: #232222;
    text-decoration: none;
}

.breadcrumb_block{
    padding-top: 80px;
    margin-bottom: 60px;
    @include mediaLaptop {
        padding-top: 40px;
        margin-bottom: 40px;
    }
    @include mediaTablet {
        padding-top: 30px;
        margin-bottom: 30px;
    }
    @include mediaPhone {
        padding-top: 20px;
        margin-bottom: 20px;
    }
}

.breadcrumb li:not(:first-child)::before {
    content: "/";
    display: inline-block;
    margin: 0px 12px;
    color: #232222;
    @include mediaPhone {
        margin: 0 5px;
    }
}

.catalog{
    margin-top: 55px;
    background-color: #f9fbfe;
    padding: 65px 0 80px;
}

.page_block{
    clear: both;
    text-align: center;
    margin-top: 60px;
}

.page_block ul{
    list-style: none;
}

.page_block li{
    display: inline-block;
    color: #9b9b9b;
    font-size: 34px;
    font-weight: 200;
    margin: 0 10px;
}

.page_block li.active{
    font-weight: 400;
    color: #3883e8;
}

.page_block li a{
    color: #9b9b9b;
    font-size: 34px;
    text-decoration: none;
    font-weight: 200;
}

.page{
    min-height: calc(100vh - 469px);
    margin-bottom: 0;
}

.page_prev, .page_next{
    width: 64px;
    height: 22px;
    display: block;
    position: relative;
}

.page_prev{
    margin-right: 36px;
}

.page_next{
    margin-left: 36px;
}

.page_prev:before, .page_prev:after, .page_next:after, .page_next:before{
    position: absolute;
    content: '';
}

.page_prev:before, .page_next:before{
    width: 100%;
    height: 1px;
    top: 10px;
    left: 0;
    background-color: #3883e8;
}

.page_prev:after, .page_next:after{
    width: 10px;
    height: 10px;
    border-left: 1px solid #3883e8;
    border-top: 1px solid #3883e8;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 1px;
    top: 5px;
}

.page_next:after{
    border: none;
    border-bottom: 1px solid #3883e8;
    border-right: 1px solid #3883e8;
    right: 1px;
    left: auto;
}

.page_prev.disabled:after, .page_next.disabled:after{
    border-color: #9b9b9b;
}

.page_prev.disabled:before, .page_next.disabled:before{
    background-color: #9b9b9b;
}

.thanks_block{
    text-align: center;
}

.payment_item:first-child, .payment_item:nth-child(5){
    background-position: center left;
}

.product_title{
    color: #3883e8;
    font-size: 30px;
    text-transform: uppercase;
}

.product_info p{
    margin-top: 0;
}

.fotorama--fullscreen .fotorama__stage__shaft:after{
    content: none;
}

.product_price_block .button_link{
    min-width: 240px;
}

.product_price_block .product_price{
    font-weight: 600;
}

.product_price_block .product_price span{
    margin-right: 50px;
    font-weight: 400;
}

.product_price_block .fotorama__arr{
    position: relative;
}

.product_price{
    font-size: 34px;
}

.product_img img{
    width: 100%;
}

.product_content{
    margin-top: 50px;
    background-color: #f9fbfe;
    padding: 50px 0;
}

.product_content .container{
    -webkit-column-count: 2; 
    -moz-column-count: 2; 
    column-count: 2;
    -webkit-column-gap: 100px; 
    -moz-column-gap: 100px;
    column-gap: 100px;
}

.page ul{
    list-style: none;
}

.page ul:not([class]) li{
    padding-left: 28px;
    position: relative;
}

.page ul:not([class]) li:before{
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: $brown;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    left: 6px;
    top: 12px;
}

.jq-number__field{
    width: 42px;
    height: 42px;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    
}

.jq-number{
    padding-right: 22px;
    margin-top: 15px;
}

.jq-number__field input{
    color: #2f2f2f;
    text-align: center;
    height: 42px;
    font-weight: 600;
}

.jq-number__spin{
    width: 20px;
    height: 20px;
    border: 1px solid #d0d0d0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: none;
}

.jq-number__spin:after{
    width: 6px;
    height: 6px;
    border: none;
    border-right: 1px solid #242424;
    border-top: 1px solid #242424;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 8px;
    left: 7px;
}

.jq-number__spin.minus:after{
    border: none;
    border-right: 1px solid #242424;
    border-bottom: 1px solid #242424;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.page_content{
    background-color: rgba(156, 137, 128, 0.15);
    padding: 50px 0;
    @include mediaTablet {
        padding: 30px 0;
    }
    @include mediaPhone {
        padding: 20px 0;
    }
}

.page_services_item{
    position: relative;
}

.page_services_img img:first-child{
    width: 85%;
}

.page_services_item a{
    color: #4a403f;
}

.page_services_img img:last-child{
    position: absolute;
    width: 56%;
    top: 58%;
    right: 0;
}

.page_services_item li{
    display: table;
}

.page_services_item p{
    margin: 24px 0;
}

.price_list table{
    width: 100%;
    border-spacing: 0;
    margin-top: 40px;
    font-size: 20px;
}

.price_list td, .price_list th{
    border: none;
    text-align: center;
    padding: 8px 0;
}

.price_list td .price_td{
    font-size: 26px;
}

.price_list td:first-child, .price_list th:first-child{
    padding-left: 28px;
    width: 50%;
    text-align: left;
}

.price_list th{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 30px;
    color: #fff;
    background-color: #3883e8;
}

.price_list th:last-child{
    width: 150px;
    text-align: center;
}

.price_list th:nth-child(2){
    text-align: center;
    width: auto;
}

.price_list tbody tr:nth-child(2n){
    background-color: #f6f9fd;
}


.price_list tbody tr:nth-child(2n-1){
    background-color: #fff;
}

.price_list .link_feedback{
    color: #4a403f;
    text-decoration: underline;
    cursor: pointer;
}

.doctors{
    margin: 10px 0 30px 0;
}

.table_price_open{
    border-bottom: 3px solid rgba(56, 131, 232, 0.2);
    position: relative;
    cursor: pointer;
    -webkit-perspective: 500px;
    -moz-perspective: 500px;
    -ms-perspective: 500px;
    perspective: 500px;
}

.page_price_list .price_list:not(:first-child) .table_price_open{
    margin-top: 70px;
}

.table_price_open.active{
    margin-top: 0;
}

.table_price_open:before{
    content: '';
    position: absolute;
    background-color: #3883e8;
    width: 30px;
    height: 3px;
    top: 100%;
    left: 0;
}

.table_price_open:after{
    content: '';
    width: 18px;
    height: 18px;
    border-bottom: 4px solid #3883e8;
    border-left: 4px solid #3883e8;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    bottom: 66px;
    right: 20px;
}

.table_price_open.active:after{
    border: 0;
    border-top: 4px solid #bdbdbd;
    border-right: 4px solid #bdbdbd;
    bottom: 50px;
}

.page_price_list .price_list_table{
    display: none;
    width: 100% !important;
    overflow: hidden;
}

.circle_page li a{
    display: block;
    width: 18px;
    height: 18px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #cfcfcf;
}

.circle_page li.active{
    width: 18px;
    height: 18px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #3883e8;
}

.circle_page{
    margin-top: 35px;
    margin-bottom: 70px;
}

.page .main_info_text{
    margin-right: 0;
    margin-left: 50px;
}

.page .main_services_icon{
    margin-top: 0;
}

.about_banner{
    margin: 40px 0;
    padding: 70px 0 80px;
    position: relative;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    min-height: 460px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
}

.text_banner{
    width: 90%;
}

.about_banner:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(56, 131, 232, 0.4);
}

.about_banner button{
    margin-top: 60px;
}

.facts_title{
    text-transform: uppercase;
    font-size: 38px;
    color: #3883e8;
    font-weight: 500;
    margin-bottom: 18px;
    margin-top: 18px;
    letter-spacing: normal;
}

.about_fact{
    letter-spacing: -0.4em;
}

.fact_item{
    display: inline-block;
    width: 48%;
    margin-right: 4%;
    letter-spacing: normal;
}

.fact_item:nth-child(2n-1){
    margin-right: 0;
}

.fact_item .fact_title{
    color: #3883e8;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1.2;
}

.about_title{
    margin: 80px 0 70px;
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 500;
    color: #4a403f;
    line-height: 1.2;
}

.article_item_img{
    width: 420px;
    display: inline-block;
    margin-right: 30px;
}

.article_item_img img{
    width: 100%;
}

.article_item_right{
    display: inline-block;
    width: calc(100% - 450px);
    letter-spacing: normal;
    vertical-align: top;
}

.article_item{
    margin-bottom: 40px;
    letter-spacing: -0.4em;
}

.article_item:last-child{
    margin-bottom: 0;
}

.article_item_title a, .news_item_title a{
    color: #4a403f;
    font-size: 30px;
    line-height: normal;
    font-weight: 300;
}

.article_item_date{
    margin: 10px 0;
}

.article_date{
    padding-left: 26px;
    position: relative;
    color: rgba(0,0,0,0.4);
    font-size: 14px;
}

.article_date:before, .article_views:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 14px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/calendar.png);
}

.article_views:before{
    background-image: url(../img/eye.png);
    width: 22px;
}

.article_views{
    padding-left: 34px;
    margin-left: 24px;
    position: relative;
    color: rgba(0,0,0,0.4);
    font-size: 14px;
}

.news_block{
    letter-spacing: -0.4em;
}

.news_item{
    vertical-align: top;
    letter-spacing: normal;
    display: inline-block;
    width: calc(86%/3);
    margin-right: 7%;
    margin-bottom: 70px;
}

.news_item:nth-child(3n){
    margin-right: 0;
}

.news_item_img img{
    width: 100%;
}

.news_item_img{
    margin-bottom: 20px;
}

.news_item_desc{
    margin: 20px 0;
}

.article_page{
    margin-bottom: 80px;
}

.article_page .article_item_date{
    margin: 0;
    margin-bottom: 10px;
}

.get_to_us{
    margin-bottom: 40px;
    @include mediaPhone {
        margin-bottom: 16px;
    }
}

.get_to_us .get_to {
    text-decoration: underline;
    cursor: pointer;
    line-height: 40px;
    display: inline-block;
    padding: 0 25px;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}

.get_to_us .get_to.active{
    background-color: $brown;
    color: #fff;
    box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
    text-decoration: none;
}


.get_to_block{
    margin-top: 40px;
    position: relative;
    display: none;
    width: 70%;
    display: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include mediaPhone {
        margin-top: 16px;
    }
}

.get_to_block.active{
    display: block;
}

.get_to_block li{
    position: relative;
    padding-left: 28px;
}

.contacts_page ul.get_to_block li .svg_icon {
    position: absolute;
    width: 16px;
    height: 16px;
    fill: $brown;
    top: 6px;
    left: 0;
    @include mediaTablet {
        top: 4px;
    }
    @include mediaPhone {
        top: 2px;
    }
}

.map {
    height: 600px;
    @include mediaTablet {
        height: 400px;
    }
    @include mediaPhone {
        height: 300px;
    }
}

.content_contact {
    padding: 50px 0 40px 0;
    @include mediaPhone {
        padding: 20px 0;
    }
}

.page .main_info{
    margin-bottom: 20px;
}

.page .service_text .service_title{
    text-transform: none;
    font-weight: 500;
}

.mobile_phone, .mobile_location{
    display: none;
}

.button_link_mobile{
    display: none;
}

.page img{
    max-width: 100%;
}

.no_available{
    color: #c3c3c3;
}

.service_price_item .available{
    font-size: 14px;
    color: #4a403f;
}

/* new style open*/

.main_service_price .owl-dots{
    margin: 0 0 20px;
}

.table_price_title{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 30px;
    color: #3883e8;
}

.stock_page .article_item{
    width: 48%;
    display: inline-block;
    margin-right: 4%;
}

.stock_page .article_item:nth-child(2n){
    margin-right: 0;
}

.service_price_button{
    margin-bottom: 10px;
}

.catalog{
    letter-spacing: -0.4em;
    padding: 65px 0 0;
}

.page_block{
    letter-spacing: normal;
    margin: 30px 0;
}

.service_price_item{
    width: 19%;
    margin-right: calc(5%/4);
    padding: 10px;
    font-size: 20px;
    letter-spacing: normal;
    vertical-align: top;
}

.service_price_item:nth-child(5n){
    margin-right: 0;
}

.service_price_button{
    font-size: 18px;
    padding: 18px 0;
    margin-top: 6px;
}

.service_price_item .service_title{
    font-size: 28px;
    margin-top: 10px;
}

.service_price_item p{
    margin: 10px 0;
}

.service_price_item .service_title{
    font-size: 22px;
}

.catalog_content{
    padding: 20px 0;
}

.product_price{
    line-height: 62px;
}

.product_price .button_link{
    vertical-align: top;
}

.product_title{
    font-size: 20px;
    margin-bottom: 10px;
}

.contacts_page .contacts_phone{
    margin-top: 0;
}

.contacts_page .main_contacts{
    background-color: #f9fbfe;
    margin-bottom: 20px;
}

.contacts_page .contacts_title{
    margin-bottom: 30px;
}

.contacts_page .contacts_phone:before{
    background-image: url(../img/svg/phone.svg);
}

.contacts_page .contacts_mail:before{
    background-image: url(../img/svg/mail.svg);
}

.contacts_page .contacts_address:before{
    background-image: url(../img/svg/map.svg);
}

.contacts_page .contacts_time:before{
    background-image: url(../img/svg/clock.svg);
}

.contacts_page .contacts_social a{
    opacity: 1;
}

.contacts_page .contacts_social .instagram{
    background-image: url(../img/svg/instagram_header.svg);
}

.contacts_page .contacts_social .facebook{
    background-image: url(../img/svg/fb_header.svg);
}

.contacts_page .contacts_social .vk{
    background-image: url(../img/svg/vk_header.svg);
}

/* new style end */

/* media */

@media (max-width: 1800px) {
    .container {
        max-width: 1800px;
        padding: 0 70px;
    }
}

@media (max-width: 1200px) {
    .container {
        padding: 0 15px;
    }
}

@media (max-width: 1540px) {
    header nav li.level1{
        margin-right: 40px;
    }
    
    header nav li.level1.last{
        margin-right: 0;
    }
    
    .stock_page .article_item_img{
        width: 370px;
    }
    
    .stock_page .article_item_right{
        width: calc(100% - 400px);
    }
}

@media (max-width: 1300px) {
    
    .stock_page .article_item_img{
        width: 100%;
        margin-right: 0;
    }
    
    .stock_page .article_item_right{
        width: 100%;
    }
    
    header nav li.level1{
        margin-right: 20px;
    }
    
    .before_title{
        font-size: 24px;
        line-height: 1.2;
    }
    
    .news_link, .service_price_button{
        font-size: 14px;
    }
    
    body{
        font-size: 16px;
    }
    
    .main_info .main_info_img{
        height: 500px;
    }
}

@media (max-width: 1200px) {
    header nav li a{
        font-size: 16px;
    }
    
    .product_info p{
        margin-bottom: 12px;
    }
    
    .article_item_title a, .news_item_title a{
        font-size: 20px;
    }
}

@media (max-width: 1024px){
    
    .page ul:not(.pagination) li:before{
        top: 8px;
    }
    
    .article_item_title a, .news_item_title a{
        font-size: 16px;
    }
    
    .mmenu_nav{
        display: none;
    }
    
    nav .button_feedback{
        display: none;
    }
    
    nav li.level2{
        margin-bottom: 0;
    }
    
    .hamburger_button.active{
        width: 0;
        margin-right: 46px;
    }
    
    .hamburger_button:before, .hamburger_button:after{
        content: '';
        position: absolute;
        width: 36px;
        height: 3px;
        background-color: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    
    .hamburger_button:before{
        top: -12px;
    }
    
    .hamburger_button:after{
        bottom: -12px;
    }
    
    .hamburger_button.active:before{
        top: 0;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    
    .hamburger_button.active:after{
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    
    .mobile_strong{
        font-weight: 600;
    }
    
    .contacts_title{
        font-size: 24px;
    }
    
    .main_info_img{
        display: none;
    }
    
    .main_info .main_info_text{
        width: 100%;
        margin-right: 0;
    }
    
    .mobile_header .phone_email {
        margin-left: 46px !important;
    }
    
    .button_link{
        font-size: 14px;
        padding: 18px;
        min-width: 230px;
    }
    
    .button_link:before{
        width: 34px;
    }
    
    .main_services .button_link{
        padding-left: 40px;
    }
    
    .main_services .button_link:before{
        right: 40px;
    }
    
    body{
        font-size: 14px;
    }
    
    .service_price_item{
        padding: 8px;
        width: 31%;
        margin-right: 3.5%;
    }
    
    .service_price_item:nth-child(5n){
        margin-right: 3.5%;
    }
    
    .service_price_item:nth-child(3n){
        margin-right: 0;
    }
    
    .owl-carousel .service_price_item{
        width: 100%;
    }
    
    .service_price_item .service_title{
        margin-top: 10px;
        font-size: 14px;
    }
    
    .service_price_item{
        font-size: 14px;
    }
    
    .service_price_item p{
        margin: 6px 0;
    }
    
    .service_price_button{
        padding: 16px 0;
        font-size: 14px;
        margin-top: 14px;
    }
    
    .main_service_price{
        padding-bottom: 0;
        padding-top: 20px;
        margin-bottom: 30px;
    }
    
    .main_service_price_container{
        margin-top: 20px;
    }
    
    .owl-nav{
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 0;
    }
    
    .catalog{
        padding-bottom: 0;
    }
    
    .page_services_img{
        margin-bottom: 40px;
    }
    
    .main_services_icon{
        margin-top: 0;
    }
    
    .page .main_info_text{
        margin-left: 0;
    }
    
    .about_banner{
        font-size: 24px;
    }
    
    .article_item_img{
        width: 300px;
    }
    
    .price_list th{
        font-size: 20px;
    }
    
    .price_list table{
        font-size: 16px;
        min-width: 600px;
    }
    
    .price_list td .price_td{
        font-size: 20px;
    }
    
    .price_list, .page_price_list .price_list_table{
        overflow: scroll;
        clear: both;
    }
    
    .page_block{
        margin: 30px 0;
    }
}

@media (max-width: 768px){
    
    .article_page .article_img{
        margin: 0 20px 10px 0;
    }
    
    .popup .button_green{
        padding-left: 40px;
        
    }
    
    .popup .button_green:before, .popup .button_green:after{
        right: 40px;
    }
    
    .get_to_block{
        width: 100%;
    }
    
    .news_item{
        margin-right: 2%;
        width: 32%;
        margin-bottom: 30px;
    }
    
    .about_title{
        font-size: 30px;
    }
    
    .main_news .main_news_img{
        display: none;
    }
    
    .main_news .main_news_text{
        width: 100%;
    }
    
    .news_link{
        font-size: 16px;
    }
    
    .footer_contacts_title{
        font-size: 14px;
    }
    
    .footer_contacts_block, .footer_contacts_block a{
        font-size: 18px;
    }
    
    .contacts_social a{
        -webkit-background-size: contain;
        background-size: contain;
        width: 30px;
        height: 30px;
    }
    
    .footer_social_links .instagram{
        -webkit-background-size: 80%;
        background-size: 80%;
    }
    
    .main_slider .slide_item{
        height: 340px;
    }
    
    .main_slider .slide_item .slider_text{
        font-size: 26px;
    }
    
    .about_banner{
        min-height: 400px;
    }
    
    .news_item_img{
        margin-bottom: 10px;
    }
    
    .news_item_desc{
        margin: 10px 0;
    }
    
    .get_to_block:before{
        top: 3px;
    }
    
    .breadcrumb_block{
        margin-bottom: 20px;
    }
}

@media (max-width: 700px){
    .product_content .container{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
    }
    
    .product_content{
        padding: 10px 0;
    }
    
    .article_item_img{
        width: 200px;
    }
    
    .article_page .article_img{
        margin: 0 10px 10px 0;
    }
    
    .mobile_header>div, .mobile_header .phone, .mobile_header .button_feedback{
        display: none;
    }
    
    .mobile_location{
        width: 18px;
        background-image: url(../img/mobile_location.png);
    }
    
    .mobile_phone{
        width: 28px;
        background-image: url(../img/mobile_phone.png);
    }
    
    .service_price_button{
        margin-bottom: 10px;
    }
    
}

@media (max-width: 600px){
    
    .contacts_page .contacts_phone{
        margin-top: 20px;
    }
    
    .stock_page .article_item{
        width: 100%;
        margin-right: 0;
    }
    
    .main_news_item{
        margin-bottom: 0;
    }
    
    .article_page .article_img{
        width: 100%;
        margin: 0 0 10px 0;
    }
    
    .article_page .article_item_date{
        width: 100%;
    }
    
    .container{
        padding: 0 5px;
    }
    
    .main_slider .slide_item{
        height: 240px;
    }
    
    .main_slider .slide_item .slider_text{
        font-size: 20px;
    }
    
    .slider_text p{
        margin: 5px 0;
    }
    
    .button_link{
        font-size: 12px;
    }
    
    .mobile_info_img.main_info_img{
        margin: 0 0 20px;
        width: 100%;
    }
    
    .facts_title{
        line-height: 1.2;
        font-size: 24px;
    }
    
    .fact_item{
        width: 100%;
        margin-right: 0;
    }
    
    .fact_item .fact_title{
        font-size: 22px;
    }
    
    .about_title{
        margin: 20px 0;
    }
    
    .main_services .button_link{
        display: none;
    }
    
    .main_news .before_title{
        display: none;
    }
    
    .button_link{
        padding: 14px;
        min-width: 200px;
    }
    
    .page_services_img img:last-child{
        display: none;
    }
    
    .page_services_img img:first-child{
        width: 100%;
    }
    
    .page_services_img{
        margin-bottom: 10px;
    }
    
    .page_services_item p{
        margin: 10px 0;
    }
    
    body{
        line-height: 1.5;
    }
    
    .product_price_block .button_link{
        margin-top: 10px;
        margin-left: 0;
        display: block;
        min-width: 150px;
    }
    
    .product_title{
        font-size: 20px;
    }
    
    .product_price{
        font-size: 24px;
    }
    
    .service_price_item{
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
    
    .main_service_price_container{
        margin-bottom: 20px;
    }
    
    .main_service_price_container .owl-dots{
        margin-top: 10px;
    }
    
    .right_contacts_form .input_block{
        width: 100%;
        max-width: 100%;
    }
    
    .footer_contacts_block:nth-child(2n){
        margin-right: 0;
    }
    
    .footer_contacts_title, .footer_contacts_block, .footer_contacts_block a{
        font-size: 12px;
    }
    
    .footer_contacts_block:before{
        width: 22px;
        -webkit-background-size: contain;
        background-size: contain;
    }
    
    .contacts_social a{
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
    
    .page_block li{
        margin: 0 4px;
    }
    
    .page_block li a, .page_block li{
        font-size: 24px;
    }
    
    .page_block{
        margin-top: 20px;
    }
    
    .page_prev, .page_next{
        width: 40px;
    }
    
    .page_prev:before, .page_next:before{
        top: 14px;
    }
    
    .page_prev:after, .page_next:after{
        top: 10px;
        width: 8px;
        height: 8px;
    }
    
    .contacts_address:before{
        -webkit-background-size: 80%;
        background-size: 80%;
    }
    
}

@media (max-width: 500px){
    
    .main_slider .slide_item:before{
        content: none;
    }
    
    .service_price_button{
        padding: 12px 0;
    }
    
    .main_service_price .main_service_link{
        display: none;
    }
    
    .service_price_button{
        background-color: #00d6aa;
    }
    
    .product_img{
        width: 100%;
        margin: 0 0 10px;
    }
    
    .catalog{
        padding-top: 30px;
        margin-top: 20px;
    }
    
    .page .service_price_item .service_title{
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 500;
    }
    
    .page .service_price_item{
        font-size: 16px;
    }
    
    .popup_bg .popup, .thanks_block{
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .news_item{
        margin-right: 0;
        width: 100%;
    }
    
    .page ul:not(.pagination) li:before{
        top: 7px;
    }
    
    .popup .button_green{
        padding-left: 0;
    }
    
    .button_link{
        min-width: 140px;
        padding: 11px 10px;
        text-align: center;
        font-weight: 300;
    }
    
    .button_link:before, .button_link:after{
        content: none;
    }
    
    .about_banner{
        padding: 30px 0;
        font-size: 20px;
        min-height: 300px;
    }
    
    .about_title{
        font-size: 24px;
    }
    
    .page_services_img{
        width: 100%;
    }
    
    .article_item_img{
        width: 100%;
        margin: 0 0 10px;
    }
    
    .main_gallery .owl-dots{
        margin-top: 0;
        position: absolute;
        top: -48px;
        right: 0;
    }
    
    .owl-dots button.owl-dot{
        width: 14px;
        height: 14px;
        margin-right: 8px;
    }
    
    .service_price_item .service_title{
        font-weight: 400;
    }
    
    .main_service_price_container .owl-dots{
        margin-top: 0;
        top: -40px;
    }
    
    .button_link_mobile{
        margin: 20px auto 0;
        display: flex;
    }
    
    .main_service_price_container{
        margin-bottom: 0;
    }
    
    .mobile_news .owl-dots{
        top: -40px;
    }
}

.types_tree {
    margin-bottom: 150px;
    text-align: center;
    @include mediaLaptop {
        margin-bottom: 50px;
    }
    @include mediaPhone {
        .flex {
            flex-wrap: wrap;
        }
    }
    &_item {
        width: 17.7%;
        box-shadow: 0 9px 18px rgba(75, 75, 75, 0.18);
        background-color: #fff;
        border-radius: 50%;
        transition: all 0.4s;
        position: relative;
        @include mediaLaptop {
            width: 22%;
        }
        @include mediaPhone {
            width: 40%;
            margin-bottom: 20px;
        }
        @include mediaSmallPhone {
            width: 46%;
        }
        &:hover {
            box-shadow: 0 2px 25px rgba($brown, 0.3);
        }
        &:before {
            content: '';
            padding-top: 100%;
            width: 100%;
            border-radius: 50%;
            display: block;
        }
        &_block {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            flex-direction: column;
        }
        &_title {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 12px;
            @include mediaLaptop {
                font-size: 20px;
            }
            @include mediaPhone {
                font-size: 16px;
            }
        }
        &_img {
            display: inline-block;
            max-width: 60%;
        }
    }
}

.title_block {
    margin-bottom: 60px;
    @include mediaLaptop {
        margin-bottom: 40px;
    }
    .section_title {
        margin-bottom: 0;
    }
    @include mediaPhone {
        margin-bottom: 26px;
        .btn {
            display: none;
        }
    }
}

.main_services_items {
    flex-wrap: wrap;
    @include mediaPhone {
        margin-bottom: 40px;
    }
}

.page .service_item {
    @include mediaPhone {
        margin-bottom: 22px;
    }
}

.service_item {
    width: 47.5%;
    margin-right: 5%;
    margin-bottom: 120px;
    &:nth-child(2n) {
        margin-right: 0;
    }
    @include mediaLaptop {
        margin-right: 0;
        width: 100%;
        margin-bottom: 50px;
    }
    @include mediaPhone {
        margin-bottom: 0;
        flex-wrap: wrap;
    }
    &_img {
        width: 45.5%;
        @include mediaLaptop {
            width: 30%;
        }
        @include mediaPhone {
            width: 100%;
            margin-bottom: 16px;
        }
        img {
            width: 100%;
        }
    }
    &_text {
        width: 43.5%;
        @include mediaLaptop {
            width: 65%;
        }
        @include mediaPhone {
            width: 100%;
        }
    }
    &_title {
        color: #4c4443;
        text-decoration: none;
        margin-bottom: 14px;
        font-size: 30px;
        line-height: 1.1;
        display: inline-block;
        @include mediaLaptop {
            font-size: 26px;
        }
        @include mediaPhone {
            font-size: 20px;
        }
    }
    &_desc {
        margin-bottom: 30px;
        @include mediaLaptop {
            margin-bottom: 20px;
        }
        &.no_desc {
            margin-bottom: 0 !important;
        }
    }
    &_link {
        font-size: 20px;
        color: #4a403f;
        @include mediaLaptop {
            font-size: 18px;
        }
    }
}

.main_news {
    color: #4f4f4f;
    line-height: 1.4;
    margin-bottom: 60px;
    &_title {
        font-size: 30px;
        color: #4a403f;
        margin-bottom: 5px;
        @include mediaLaptop {
            font-size: 26px;
        }
    }
    &_desc {
        margin-bottom: 16px;
    }
    &_date {
        color: #afafaf;
    }
    &_item {
        margin-bottom: 40px;
        @include mediaLaptop {
            margin-bottom: 26px;
        }
    }
}

.main_gallery {
    margin-bottom: 100px;
    @include mediaLaptop {
        margin-bottom: 50px;
    }
}

.gallery {
    &_item {
        flex-wrap: wrap;
        &_link {
            position: relative;
            overflow: hidden;
            width: 23.8%;
            margin-bottom: 28px;
            @include mediaLaptop {
                margin-bottom: 16px;
            }
            @include mediaPhone {
                width: 48%;
                margin-bottom: 12px;
            }
            &.big {
                width: 49.2%;
                @include mediaPhone {
                    width: 100%;
                }
            }
            &:hover {
                .hover_gallery {
                    opacity: 1;
                }
            }
            img {
                width: 100%;
                display: block;
            }
            .hover_gallery {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all 0.4s;
                background-color: rgba(#262626, 0.7);
                @include mediaTablet {
                    opacity: 1;
                }
            }
            .loupe_gallery {
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background-color: rgba($brown, 0.6);
                position: absolute;
                right: 14px;
                top: 16px;
                @include mediaTablet {
                    display: none;
                }
                .svg_icon {
                    width: 20px;
                    height: 20px;
                    fill: #fff;
                }
            }
            .gallery_item_name {
                font-size: 30px;
                position: absolute;
                color: #fff;
                text-transform: uppercase;
                left: 20px;
                bottom: 20px;
                line-height: 1;
                @include mediaTablet {
                    font-size: 20px;
                    left: 10px;
                    bottom: 10px;
                }
                @include mediaPhone {
                    font-size: 16px;
                }
            }
        }
    }
}

.footer {
    background-color: #323232;
    color: #fff;
    .flex.flex_footer {
        height: 194px;
        @include mediaLaptop {
            height: auto;
            flex-wrap: wrap;
            justify-content: center;
            padding: 30px 0;
        }
    }
    .logo {
        height: 33px;
        width: 19.5%;
        @include mediaLaptop {
            margin-right: 10%;
        }
        @include mediaPhone {
            width: 150px;
            height: 25px;
            margin-bottom: 20px;
            margin-right: 0;
        }
    }
    &_menu {
        list-style: none;
        margin-bottom: 0;
        width: 48%;
        @include mediaLaptop {
            width: 70.5%;
        }
        @include mediaPhone {
            width: 100%;
        }
        @include mediaSmallPhone {
            flex-wrap: wrap;
            justify-content: center !important;
        }
        &_link {
            font-size: 20px;
            font-weight: 300;
            text-decoration: none;
            transition: color 0.4s;
            color: #fff;
            @include mediaPhone {
                font-size: 16px;
            }
            @include mediaSmallPhone {
                padding: 4px 10px;
            }
        }
    }
    &_bm {
        @include mediaLaptop {
            padding-top: 30px;
        }
        @include mediaPhone {
            padding-top: 20px;
        }
        &:before {
            content: '';
            width: 96px;
            height: 45px;
            @include bg;
            background-image: url(../img/BM-logo.png);
            margin-right: 20px;
            @include mediaPhone {
                width: 50px;
                height: 28px;
            }
        }
        a {
            color: #86b2cd;
            text-decoration: none;
        }
    }
}

.main_contacts {
    color: #000;
    @include bg(cover);
    background-image: url(../img/contacts_img.jpg);
    padding: 50px 0;
    @include mediaTablet {
        padding: 30px 0;
    }
    @include mediaPhone {
        padding: 22px 0;
        .flex_contacts {
            flex-wrap: wrap;
        }
    }
    .section_title {
        margin-bottom: 30px;
        line-height: 1.3;
        @include mediaTablet {
            margin-bottom: 16px;
        }
    }
    .left_contacts {
        width: 37%;
        padding-right: 20px;
        border-right: 1px solid rgba(0,0,0,0.35);
        @include mediaPhone {
            width: 100%;
            order: 2;
            border: none;
        }
        &_block {
            padding-left: 62px;
            position: relative;
            margin-bottom: 24px;
            font-size: 16px;
            @include mediaLaptop {
                padding-left: 30px;
            }
            @include mediaTablet {
                margin-bottom: 16px;
            }
            .svg_icon {
                fill: transparent;
                stroke: $brown;
                width: 24px;
                height: 35px;
                position: absolute;
                top: 0;
                left: 0;
                @include mediaLaptop {
                    width: 16px;
                    height: 20px;
                    top: 5px;
                }
            }
            .header_bottom_block_phone {
                color: #000;
                @include mediaTablet {
                    font-size: 24px;
                }
            }
        }
        .social_links {
            padding-left: 62px;
            @include mediaLaptop {
                padding-left: 30px;
            }
        }
    }
    .right_contacts {
        width: 63%;
        padding-left: 115px;
        @include mediaLaptop {
            padding-left: 34px;
        }
        @include mediaPhone {
            width: 100%;
            padding-left: 0;
            margin-bottom: 16px;
        }
        .section_title {
            margin-bottom: 0;
        }
        &_desc {
            font-size: 30px;
            margin-bottom: 30px;
            font-weight: 300;
            @include mediaTablet {
                margin-bottom: 16px;
                font-size: 20px;
            }
            @include mediaPhone {
                font-size: 14px;
            }
        }
        .inputs_block {
            margin-bottom: 50px;
            flex-wrap: wrap;
            @include mediaLaptop {
                margin-bottom: 0;
            }
        }
        .input_block {
            width: 45.8%;
            margin-bottom: 40px;
            @include mediaTablet {
                width: 100%;
                margin-bottom: 20px;
            }
            @include mediaPhone {
                margin-bottom: 14px;
            }
            .input {
                height: 44px;
                border-bottom: 1px solid #9f9e9e;
                color: #000;
                @include mediaPhone {
                    height: 30px;
                }
            }
        }
    }
}

.popup_bg {
    .checkbox_block {
        margin-bottom: 20px;
    }
}

.checkbox_block {
    position: relative;
    padding-left: 46px;
    font-weight: 300;
    margin-bottom: 0;
    text-align: left;
    @include mediaPhone {
        padding-left: 32px;
    }
    .conf_checkbox {
        position: absolute;
        left: 5px;
        top: 10px;
        opacity: 0;
        width: 2px !important;
        height: 2px !important;
        margin: 0 !important;
        &:checked + .checked:before {
            opacity: 1;
        }
    }
    .checked {
        position: absolute;
        left: 0;
        top: 4px;
        width: 22px;
        height: 22px;
        border: 1px solid #000;
        text-align: center;
        @include mediaLaptop {
            top: 0;
        }
        &:before {
            content: '\2714';
            font: 20px/22px Arial;
            display: inline-block;
            vertical-align: top;
            opacity: 0;
            transition: opacity 0.4s;
        }
    }
    a {
        font-weight: 500;
        color: #000;
    }
}

.portfolio_title {
    text-align: center;
}

.portfolio_menu {
    padding: 80px 100px 0;
    margin-bottom: 60px;
    list-style: none;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    color: #232222;
    flex-wrap: wrap;
    @include mediaLaptop {
        padding: 50px 50px 0;
        margin-bottom: 20px;
    }
    @include mediaTablet {
        font-size: 16px;
    }
    @include mediaPhone {
        font-size: 14px;
        padding: 30px 10px 0;
    }
    &_item {
        margin-bottom: 20px;
        @include mediaTablet {
            margin-bottom: 14px;
        }
        &.active, &:hover {
            .portfolio_menu_link {
                color: $brown;
            }
        }
    }
    &_add {
        margin: 0 20px 20px;
        &:last-child {
            display: none;
        }
        @include mediaTablet {
            margin: 0 10px 14px;
        }
    }
    &_link {
        color: #626262;
        text-decoration: none;
    }
}

.gallery_container {
    flex-wrap: wrap;
    &_item {
        width: 31%;
        margin-right: 3.5%;
        text-align: center;
        margin-bottom: 100px;
        &:last-child, &:nth-child(3n) {
            margin-right: 0;
        }
        @include mediaTablet {
            margin-bottom: 40px;
        }
        @include mediaPhone {
            width: 48%;
            margin-right: 4%;
            &:nth-child(3n) {
                margin-right: 4%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
            margin-bottom: 30px;
        }
        @include mediaSmallPhone {
            width: 100%;
            margin-right: 0;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    &_link {
        display: block;
        margin-bottom: 50px;
        @include mediaLaptop {
            margin-bottom: 30px;
        }
        @include mediaPhone {
            margin-bottom: 16px;
        }
    }
    &_img {
        display: block;
        width: 100%;
    }
}

.page_gallery {
    margin-bottom: 30px;
    flex-wrap: wrap;
    .gallery_item {
        &_link {
            width: 23.8%;
            margin-right: 1.6%;
            &:last-child, &:nth-child(4n) {
                margin-right: 0;
            }
            @include mediaTablet {
                width: 48%;
                margin-right: 4%;
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
            @include mediaSmallPhone {
                width: 100%;
                margin-right: 0 !important;
            }
            img {
                width: 100%;
                display: block;
            }
        }
    }
}

.gallery_pagination {
    margin-bottom: 90px;
    @include mediaTablet {
        margin-bottom: 60px;
    }
    @include mediaPhone {
        margin-bottom: 40px;
    }
    .pagination {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        font-size: 34px;
        .page-item {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            .page-link {
                text-decoration: none;
                color: #000;
                &:hover {
                    color: #e8d29e;
                }
            }
            &.active {
                .page-link {
                    color: #e8d29e;
                }
            }
            &.control {
                margin-right: 0;
            }
            .arrow {
                display: block;
                width: 64px;
                height: 18px;
                position: relative;
                &:before, &:after {
                    content: '';
                    position: absolute;
                }
                &:before {
                    width: 100%;
                    height: 1px;
                    left: 0;
                    top: 50%;
                    background-color: #000;
                }
                &:after {
                    width: 9px;
                    height: 9px;
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                    transform: rotate(45deg);
                    right: 0;
                    top: 5px;
                }
                &.next {
                    margin-left: 30px;
                }
                &.prev {
                    transform: rotate(180deg);
                    margin-right: 50px;
                }
            }
        }
    }
}

.article_block {
    margin-bottom: 100px;
    font-size: 20px;
    @include mediaTablet {
        font-size: 18px;
        margin-bottom: 60px;
    }
    @include mediaPhone {
        font-size: 14px;
        margin-bottom: 30px;
    }
    &_img {
        float: left;
        margin-right: 40px;
        margin-bottom: 10px;
        width: 48.8%;
        @include mediaTablet {
            margin-right: 20px;
        }
        @include mediaPhone {
            width: 100%;
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
    &_title {
        line-height: 1;
    }
    &_intro {
        font-size: 30px;
        font-weight: 700;
        color: $brown;
        margin-bottom: 30px;
        line-height: 1.4;
        @include mediaTablet {
            font-size: 16px;
            margin-bottom: 22px;
        }
        @include mediaPhone {
            font-size: 20px;
            margin-bottom: 16px;
        }
    }
}

.articles {
    margin-bottom: 60px;
    @include mediaTablet {
        margin-bottom: 40px;
    }
    @include mediaPhone {
        margin-bottom: 30px;
    }
    &_flex {
        flex-wrap: wrap;
    }
    &_item {
        margin-bottom: 60px;
        width: 31%;
        margin-right: 3.5%;
        text-decoration: none;
        color: #000;
        position: relative;
        box-shadow: 0 0 30px rgba(75, 75, 75, 0.29);
        &:hover {
            color: #000;
            .articles_item_bg {
                opacity: 1;
            }
        }
        &:last-child, &:nth-child(3n) {
            margin-right: 0;
        }
        @include mediaTablet {
            width: 48%;
            margin-right: 4%;
            &:nth-child(3n) {
                margin-right: 4%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
            margin-bottom: 40px;
        }
        @include mediaPhone {
            width: 100%;
            margin-right: 0 !important;
        }
        &_img {
            width: 100%;
            display: block;
        }
        &_bg {
            position: absolute;
            opacity: 0;
            left: -0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: opacity 0.4s;
            background-color: rgba(#fff, 0.7);
            .loupe {
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background-color: rgba(#fff, 0.6);
                position: absolute;
                right: 20px;
                top: 20px;
                .svg_icon {
                    width: 20px;
                    height: 20px;
                    fill: #505050;
                }
            }
        }
        &_bottom {
            margin-top: auto;
            text-align: center;
            padding: 20px;
            font-size: 28px;
            line-height: 1.2;
            @include mediaLaptop {
                font-size: 22px;
            }
            @include mediaPhone {
                font-size: 16px;
            }
        }
        &_date {
            margin-bottom: 6px;
        }
        &_desc {
            font-weight: 700;
        }
        &_top {
            position: relative;
            .text {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(#000,0.5);
                width: 100%;
                height: 100%;
            }
        }
        &_title {
            font-family: $tinos;
            font-size: 51px;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            line-height: 1.3;
            padding: 0 30px;
            @include mediaLaptop {
                font-size: 36px;
            }
            @include mediaPhone {
                font-size: 26px;
                padding: 0 20px;
            }
        }
    }
}

.service_content {
    margin-bottom: 80px;
    @include mediaTablet {
        margin-bottom: 40px;
    }
    @include mediaPhone {
        margin-bottom: 20px;
    }
}

.article_page .article_img{
    margin: 0 20px 10px 0;
    float: left;
    max-width: 400px;
    @include mediaPhone {
        width: 100%;
        max-width: none;
        float: none;
        margin-bottom: 16px;
    }
}

.article_page .page_title {
    margin-bottom: 30px;
    @include mediaPhone {
        margin-bottom: 18px;
    }
}

.inputs_bottom {
    @include mediaSmallDesktop {
        flex-wrap: wrap;
        .checkbox_block {
            order: -1;
            width: 100%;
            margin-bottom: 15px;
        }
    }
}

.gallery_content {
    margin-bottom: 50px;
    @include mediaPhone {
        margin-bottom: 30px;
    }
}

input.firstname {
    display: none;
}

.jq-file input {
    position: absolute;
    border: none;
    height: auto;
}

.placeholder.file {
    margin-bottom: 22px;
}

.error_files {
    .error {
        padding-top: 10px;
        display: block;
        text-align: left;
    }
}

.jq-file {
    display: block;
    max-width: 100%;
}