$brown: #ddbc6f;
$tinos: 'Fira Sans', sans-serif;

.flex {
    display: flex;
    &.jsb {
        justify-content: space-between;
    }
    &.jfs {
        justify-content: flex-start;
    }
    &.jfe {
        justify-content: flex-end;
    }
    &.jc {
        justify-content: center;
    }
    &.ac {
        align-items: center;
    }
    &.afs {
        align-items: flex-start;
    }
    &.afe {
        align-items: flex-end;
    }
}
.inline_flex {
    display: inline-flex;
    &.jsb {
        justify-content: space-between;
    }
    &.jfs {
        justify-content: flex-start;
    }
    &.jfe {
        justify-content: flex-end;
    }
    &.jc {
        justify-content: center;
    }
    &.ac {
        align-items: center;
    }
    &.afs {
        align-items: flex-start;
    }
    &.afe {
        align-items: flex-end;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $tinos;
    line-height: 1.1;
}

h1 {
    font-size: 40px;
    @include mediaTablet {
        font-size: 30px;
    }
    @include mediaPhone {
        font-size: 26px;
    }
}

h2 {
    font-size: 34px;
    @include mediaTablet {
        font-size: 28px;
    }
    @include mediaPhone {
        font-size: 24px;
    }
}

h3 {
    font-size: 30px;
    @include mediaTablet {
        font-size: 24px;
    }
    @include mediaPhone {
        font-size: 20px;
    }
}

h4 {
    font-size: 26px;
    @include mediaTablet {
        font-size: 20px;
    }
    @include mediaPhone {
        font-size: 18px;
    }
}

h5, h6 {
    font-size: 22px;
    @include mediaTablet {
        font-size: 18px;
    }
    @include mediaPhone {
        font-size: 16px;
    }
}

.btn {
    @include inline-flex;
    box-shadow: 4px 2px 7px 1px rgba(75, 75, 75, 0.18), 0 0 7px rgba(255, 255, 255, 0.35);
    border: 3px solid $brown;
    height: 75px;
    width: 320px;
    max-width: 100%;
    text-decoration: none;
    font-size: 18px;
    color: #484848;
    text-transform: uppercase;
    transition: all 0.4s;
    font-weight: 400;
    &:hover {
        background-color: $brown;
        border-color: $brown;
        color: #484848;
    }
    &:focus {
        background-color: #dcc284;
        border-color: #dcc284;
    }
    @include mediaLaptop {
        height: 50px;
    }
    @include mediaPhone {
        width: 250px;
        font-size: 16px;
        padding: 0 15px;
    }
    .arrow {
        width: 46px;
        height: 12px;
        position: relative;
        margin-left: 15px;
        margin-bottom: 1px;
        @include mediaPhone {
            width: 30px;
        }
        &:before, &:after {
            content: '';
            position: absolute;
        }
        &:before {
            width: 100%;
            height: 1px;
            left: 0;
            top: 50%;
            background-color: #000;
        }
        &:after {
            width: 7px;
            height: 7px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            transform: rotate(45deg);
            right: 0;
            top: 3px;
        }
    }
}

.section_title {
    font-size: 50px;
    font-family: $tinos;
    font-weight: 400;
    color: #190f0b;
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
    @include mediaLaptop {
        margin-bottom: 26px;
        font-size: 36px;
    }
    @include mediaPhone {
        font-size: 28px;
        margin-bottom: 16px;
    }
}

.page {
    .section_title {
        font-size: 40px;
        margin-bottom: 30px;
        @include mediaLaptop {
            font-size: 32px;
            margin-bottom: 20px;
        }
        @include mediaPhone {
            font-size: 26px;
            margin-bottom: 16px;
        }
    }
}

a {
    transition: color 0.4s;
    &:hover {
        color: $brown;
    }
}

.page_title {
    color: #190f0b;
    font-size: 46px;
    font-weight: 400;
    font-family: $tinos;
    margin-bottom: 70px;
    @include mediaTablet {
        font-size: 36px;
        margin-bottom: 30px;
    }
    @include mediaPhone {
        font-size: 28px;
        margin-bottom: 25px;
    }
}

input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], select, textarea {
    border-radius: 0;
}

ul, li {
    margin: 0;
    padding: 0;
}

pre, blockquote, figure, ul:not([class]), ul:not([class]) li, ol {
    margin-bottom: 18px;
    &:last-child {
        margin-bottom: 0;
    }
    @include mediaLaptop {
        margin-bottom: 14px;
    }
}

p {
    margin-top: 0;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    @include mediaLaptop {
        margin-bottom: 14px;
    }
}

.two_column {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 80px;
    @include mediaLaptop {
        font-size: 18px;
        margin-bottom: 50px;
    }
    @include mediaPhone {
        flex-wrap: wrap;
        font-size: 14px;
    }
    &_item {
        width: 45.7%;
        @include mediaPhone {
            width: 100%;
            margin-bottom: 16px;
        }
    }
    .img {
        width: 100%;
        margin-bottom: 20px;
        @include mediaLaptop {
            margin-bottom: 14px;
        }
    }
}

img {
    max-width: 100%;
}

